import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import Block from 'components/elements/Blocks/BlockDefault'
import NavLink from 'components/shared/NavLink'
import Circle from 'components/elements/Vectors/Circle'
import Crumbs from 'components/elements/Misc/Crumbs'

// Images
import Arrow from 'img/arrow-rounded.inline.svg'

// Interface
import { OverviewProps } from 'components/flex/Posts/Overview/OverviewShell'

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & h1,h2 {
    color: ${({ theme }) => theme.color.light};
  }

  & table tr {
    display: flex;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: end;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
`

const FiltersWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding-top: 11rem;
  padding-bottom: 4rem;
`

const Vacatures = ({ fields, pageContext }: OverviewProps) => {
  const {
    allWpVacancy,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacancyQueryQuery>(graphql`
    query vacancyQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const [currentIndex, setCurrentIndex] = useState(-1)

  const posts: unknown = allWpVacancy.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="blog"
        limit={Number(fields.limit)}
      >
        {/* <FiltersWrapper className="position-relative">
          <Circle
            identifier="cases-banner-circle"
            absolute
            width={283}
            height={283}
            bottom={-20}
            right={-10}
          />
          <Circle
            identifier="cases-small-banner-circle"
            absolute
            width={120}
            height={120}
            top={40}
            right={13}
            strokeWidth={25}
          />
          <div className="container">
            <div className="mb-4">
              <Crumbs pageContext={pageContext} />
            </div>
            <Content content={fields.description} className="mb-4" />
            <BlogFilters />
          </div>
        </FiltersWrapper> */}
        <BlogGrid
          fields={fields}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
      </BlogBink>
    </section>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter) <{ selected: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border: 2px solid ${({ theme }) => theme.color.light};
  border-radius: 50px;
  padding: 0.5rem 1rem;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${({ theme }) => theme.color.secondary};
      border: 2px solid ${({ theme }) => theme.color.secondary};

      &:before {
        position: absolute;
        padding: 0 5px;
        content: 'X';
        font-weight: ${({ theme }) => theme.font.weight.bold};
        top: 7.3px;
        right: -12.5px;
        color: ${({ theme }) => theme.color.light};
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.primary};
      }
    `}

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary};
    border-color: ${({ theme }) => theme.color.secondary};
  }

  @media (min-width: 992px) {
    min-width: 136px;
  }

  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }
`

interface BlogFiltersProps { }

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div>
      {/* <StyledBlogFilter
        key="all-cases"
        id="all-cases"
        selected={Number(blogBink.selectedFilters.length) < 1}
        className="me-4"
      >
        Alle
      </StyledBlogFilter> */}
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="me-4"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </div>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  // eslint-disable-next-line no-unused-vars
  setCurrentIndex: (index: number) => void
  currentIndex: number
}

const BlogGrid: React.FC<BlogGridProps> = ({
  fields,
  setCurrentIndex,
  currentIndex,
}) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row gx-lg-5 mt-5 pt-5">
        {blogBink.posts.length > 0 ? (
          blogBink.posts.map((post, index: number) => {
            const { node }: any = post

            return (
              <div className="col-12 col-md-4 mb-5">
                <Post
                  key={post.node.databaseId}
                  node={node}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  index={index}
                  blogFields={fields}
                />
              </div>
            )
          })
        ) : (
          <div>
            <h2>
              Helaas, we kunnen op dit moment (nog) geen blogs tonen voor deze
              categorie.
            </h2>
            <p>Selecteer een andere categorie of probeer het later nog eens.</p>
          </div>
        )}

        {blogBink.showMoreButton && (
          <div className="my-5 pb-5 text-center">
            <ButtonPrimary to="/" isCustom>
              <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
            </ButtonPrimary>
          </div>
        )}
      </div>
    </div>
  )
}

interface PostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    recap: {
      thumbnail: any
      excerpt: string
    }
  }
  // eslint-disable-next-line no-unused-vars
  setCurrentIndex: (index: number) => void
  index: number
  currentIndex: number
}

const Image = styled(Plaatjie)`
  height: 139px;
  width: 76px;

  @media (max-width: 575px) {
    height: 103px;
    width: 56px;
  }

  @media (max-width: 399px) {
    height: 76px;
    width: 42px;
  }
`

const Title = styled.div`
  bottom: 0;
  width: 100%;

  @media (min-width: 576px) {
    border-radius: 50px;

    background: linear-gradient(
      180deg,
      rgba(138, 146, 255, 0) 31.77%,
      #636eff 80.21%
    );

    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 575px) {
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      rgba(138, 146, 255, 0) 11.77%,
      #636eff 80.21%
    );

    & svg {
      width: 20px;
      height: 20px;
    }

    & h2 {
      word-break: break-all;
    }
  }
`

const Wrap = styled.div`
  @media (min-width: 576px) {
    border-radius: 50px;
  }

  @media (max-width: 575.98px) {
    border-radius: 12px;
  }
`

const StyledVacancy = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 50px;
  color: ${({ theme }) => theme.color.light} !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    aspect-ratio: 1/1;

    & .vacancy-icon {
      bottom: 35px;
      right: 35px;
    }
  }

  @media (min-width: 1400px) {
    padding: 2rem 4rem;
  }

  @media (max-width: 991.98px) {
    padding: 1rem;

    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
    }

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 767.98px) {
    & .vacancy-icon {
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }

    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const Background = styled.div`
  border-radius: 50px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(138, 146, 255, 0) 31.77%,
    #636eff 80.21%
  );
  mix-blend-mode: multiply;
`

const ArrowWrapper = styled(motion.div)`
  @media (min-width: 768px) {
    bottom: 35px;
    right: 35px;
  }
  @media (max-width: 767.98px) {
    bottom: 10.5px;
    right: 22.5px;
  }
  @media (max-width: 575.98px) {
    bottom: 10px;
    right: 18px;
    & svg {
      width: 30px;
      height: 30px;
    }
  }
`

const Post: React.FC<PostProps> = ({
  node,
  setCurrentIndex,
  currentIndex,
  index,
}) => (
  <StyledVacancy className="position-relative d-flex align-items-center h-100 mb-4 mb-lg-0">
    <NavLink to={node.uri}>
      <Background className="position-absolute" />
      <div className="position-relative" style={{ zIndex: 4 }}>
        <h2 className="text-center text-white font-weight-bold px-md-2 ps-2 pe-5 mb-0 py-md-0 py-1">
          {node.title}
        </h2>
      </div>
      <ArrowWrapper className="position-absolute">
        <div className="d-flex align-items-center">
          <Arrow className="rounded-circle" />
        </div>
      </ArrowWrapper>
    </NavLink>
  </StyledVacancy>
)

export default Vacatures
