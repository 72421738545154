import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import Block from 'components/elements/Blocks/BlockDefault'
import NavLink from 'components/shared/NavLink'
import Circle from 'components/elements/Vectors/Circle'
import Crumbs from 'components/elements/Misc/Crumbs'

// Images
import Arrow from 'img/arrow-rounded.inline.svg'

// Interface
import { OverviewProps } from 'components/flex/Posts/Overview/OverviewShell'

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & h1,h2 {
    color: ${({ theme }) => theme.color.light};
  }

  & table tr {
    display: flex;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: end;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
`

const FiltersWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding-top: 11rem;
  padding-bottom: 4rem;
`

const Blog = ({ fields, pageContext }: OverviewProps) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const [currentIndex, setCurrentIndex] = useState(-1)

  const posts: unknown = allWpPost.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="blog"
        limit={Number(fields.limit)}
      >
        <FiltersWrapper className="position-relative">
          <Circle
            identifier="cases-banner-circle"
            absolute
            width={283}
            height={283}
            bottom={-20}
            right={-10}
          />
          <Circle
            identifier="cases-small-banner-circle"
            absolute
            width={120}
            height={120}
            top={40}
            right={13}
            strokeWidth={25}
          />
          <div className="container">
            <div className="mb-4">
              <Crumbs pageContext={pageContext} />
            </div>
            <Content content={fields.description} className="mb-4" />
            <BlogFilters />
          </div>
        </FiltersWrapper>
        <BlogGrid
          fields={fields}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
      </BlogBink>
    </section>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter) <{ selected: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border: 2px solid ${({ theme }) => theme.color.light};
  border-radius: 50px;
  padding: 0.5rem 1rem;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${({ theme }) => theme.color.secondary};
      border: 2px solid ${({ theme }) => theme.color.secondary};

      &:before {
        position: absolute;
        padding: 0 5px;
        content: 'X';
        font-weight: ${({ theme }) => theme.font.weight.bold};
        top: 7.3px;
        right: -12.5px;
        color: ${({ theme }) => theme.color.light};
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.primary};
      }
    `}

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary};
    border-color: ${({ theme }) => theme.color.secondary};
  }

  @media (min-width: 992px) {
    min-width: 136px;
  }

  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }
`

interface BlogFiltersProps { }

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div>
      {/* <StyledBlogFilter
        key="all-cases"
        id="all-cases"
        selected={Number(blogBink.selectedFilters.length) < 1}
        className="me-4"
      >
        Alle
      </StyledBlogFilter> */}
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="me-4"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </div>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  // eslint-disable-next-line no-unused-vars
  setCurrentIndex: (index: number) => void
  currentIndex: number
}

const BlogGrid: React.FC<BlogGridProps> = ({
  fields,
  setCurrentIndex,
  currentIndex,
}) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row gx-lg-5 mt-5 pt-5">
        {blogBink.posts.length > 0 ? (
          blogBink.posts.map((post, index: number) => {
            const { node }: any = post

            return (
              <div className="col-6 col-md-4 mb-5">
                <Post
                  key={post.node.databaseId}
                  node={node}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  index={index}
                  blogFields={fields}
                />
              </div>
            )
          })
        ) : (
          <div>
            <h2>
              Helaas, we kunnen op dit moment (nog) geen blogs tonen voor deze
              categorie.
            </h2>
            <p>Selecteer een andere categorie of probeer het later nog eens.</p>
          </div>
        )}

        {blogBink.showMoreButton && (
          <div className="my-5 pb-5 text-center">
            <ButtonPrimary to="/" isCustom>
              <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
            </ButtonPrimary>
          </div>
        )}
      </div>
    </div>
  )
}

interface PostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    recap: {
      thumbnail: any
      excerpt: string
    }
  }
  // eslint-disable-next-line no-unused-vars
  setCurrentIndex: (index: number) => void
  index: number
  currentIndex: number
}

const Image = styled(Plaatjie)`
  height: 139px;
  width: 76px;

  @media (max-width: 575px) {
    height: 103px;
    width: 56px;
  }

  @media (max-width: 399px) {
    height: 76px;
    width: 42px;
  }
`

const Title = styled.div`
  bottom: 0;
  width: 100%;

  @media (min-width: 576px) {
    border-radius: 50px;

    background: linear-gradient(
      180deg,
      rgba(138, 146, 255, 0) 31.77%,
      #636eff 80.21%
    );

    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 575px) {
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      rgba(138, 146, 255, 0) 11.77%,
      #636eff 80.21%
    );

    & svg {
      width: 20px;
      height: 20px;
    }

    & h2 {
      word-break: break-all;
    }
  }
`

const Wrap = styled.div`
  @media (min-width: 576px) {
    border-radius: 50px;
  }

  @media (max-width: 575.98px) {
    border-radius: 12px;
  }
`

const Post: React.FC<PostProps> = ({
  node,
  setCurrentIndex,
  currentIndex,
  index,
}) => (
  <Wrap className="overflow-hidden">
    <motion.div
      onHoverStart={() => setCurrentIndex(index)}
      onHoverEnd={() => setCurrentIndex(-1)}
    >
      <NavLink to={node.uri}>
        <Block className="position-relative">
          <div
            className="position-absolute d-sm-none"
            style={{
              background: 'rgba(99, 109, 255, 0.2)',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
          />
          <motion.div
            initial={{ scale: 1.1 }}
            animate={currentIndex === index ? { scale: 1 } : { scale: 1.1 }}
          >
            <Image
              image={node.recap.thumbnail}
              alt=""
              className="w-100 h-100 overflow-hidden"
            />
          </motion.div>
          <Title
            className="d-flex align-items-end position-absolute px-sm-4 px-3 py-3 h-100"
            style={{ zIndex: 3 }}
          >
            <div className="d-flex flex-column flex-sm-row align-items-sm-center">
              <h2 className="text-white me-sm-3">{node.title}</h2>
              <motion.div
                initial={{ rotate: 0 }}
                animate={
                  currentIndex === index ? { rotate: 90 } : { rotate: 0 }
                }
              >
                <Arrow className="rounded-circle" />
              </motion.div>
            </div>
          </Title>
        </Block>
      </NavLink>
    </motion.div>
  </Wrap>
)

export default Blog
