import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import Block from 'components/elements/Blocks/BlockDefault'
import NavLink from 'components/shared/NavLink'
import Circle from 'components/elements/Vectors/Circle'
import Crumbs from 'components/elements/Misc/Crumbs'

// Images
import Arrow from 'img/arrow-large.inline.svg'

// Interface
import { OverviewProps } from 'components/flex/Posts/Overview/OverviewShell'

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & h1,h2 {
    color: ${({ theme }) => theme.color.light};
  }

  & table tr {
    display: flex;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: end;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
`

const FiltersWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding-top: 11rem;
  padding-bottom: 4rem;
`

const Cases = ({ fields, pageContext }: OverviewProps) => {
  const {
    allWpCase,
    allWpService,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.casesQueryQuery>(graphql`
    query casesQuery {
      allWpCase(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalCaseFragment
          }
        }
      }
      allWpService(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const [currentIndex, setCurrentIndex] = useState(-1)

  const cases: unknown = allWpCase.edges

  return (
    <section>
      <BlogBink
        posts={cases as BlogBinkPosts}
        categories={allWpService.edges}
        categoryName="services"
        id="cases"
        limit={Number(fields.limit)}
      >
        <FiltersWrapper className="position-relative">
          <Circle
            identifier="cases-banner-circle"
            absolute
            width={283}
            height={283}
            bottom={-20}
            right={-10}
          />
          <Circle
            identifier="cases-small-banner-circle"
            absolute
            width={120}
            height={120}
            top={40}
            right={13}
            strokeWidth={25}
          />
          <div className="container">
            <div className="mb-4">
              <Crumbs pageContext={pageContext} />
            </div>
            <Content content={fields.description} className="mb-4" />
            <BlogFilters />
          </div>
        </FiltersWrapper>
        <BlogGrid
          fields={fields}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
      </BlogBink>
    </section>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter) <{ selected: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border: 2px solid ${({ theme }) => theme.color.light};
  border-radius: 50px;
  padding: 0.5rem 1rem;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${({ theme }) => theme.color.secondary};
      border: 2px solid ${({ theme }) => theme.color.secondary};

      &:before {
        position: absolute;
        padding: 0 5px;
        content: 'X';
        font-weight: ${({ theme }) => theme.font.weight.bold};
        top: 7.3px;
        right: -12.5px;
        color: ${({ theme }) => theme.color.light};
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.primary};
      }
    `}

  @media (min-width: 992px) {
    min-width: 136px;
  }

  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }
`

interface BlogFiltersProps { }

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div>
      {/* <StyledBlogFilter
        key="all-cases"
        id="all-cases"
        selected={Number(blogBink.selectedFilters) < 1}
        className="me-4"
      >
        Alle
      </StyledBlogFilter> */}
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="me-4"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </div>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  // eslint-disable-next-line no-unused-vars
  setCurrentIndex: (index: number) => void
  currentIndex: number
}

const BlogGrid: React.FC<BlogGridProps> = ({
  fields,
  setCurrentIndex,
  currentIndex,
}) => {
  const blogBink = useBlogBink()

  return (
    <div className="container" style={{ minHeight: '50vh' }}>
      <div className="ms-sm-5 row gx-5 mt-5 pt-5">
        {blogBink.posts.length > 0 ? (
          blogBink.posts.map((post, index: number) => {
            const { node }: any = post

            return (
              <div key={post.node.id} className="col-xl-6 mb-5 pb-5 pb-sm-0">
                <div className="mx-4">
                  <BlogGridPost
                    node={node}
                    blogFields={fields}
                    setCurrentIndex={setCurrentIndex}
                    index={index}
                    currentIndex={currentIndex}
                  />
                </div>
              </div>
            )
          })
        ) : (
          <div>
            <h2>
              Helaas, we kunnen op dit moment (nog) geen cases tonen voor deze
              categorie.
            </h2>
            <p>Selecteer een andere categorie of probeer het later nog eens.</p>
          </div>
        )}

        {blogBink.showMoreButton && (
          <div className="my-5 pb-5 text-center">
            <ButtonPrimary to="/" isCustom>
              <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
            </ButtonPrimary>
          </div>
        )}
      </div>
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    recap: {
      thumbnail: any
      excerpt: string
    }
  }
  // eslint-disable-next-line no-unused-vars
  setCurrentIndex: (index: number) => void
  index: number
  currentIndex: number
}

const Thumbnail = styled.div`
  width: 168px;
  height: 168px;
  background-color: ${({ theme }) => theme.color.grey};

  @media (min-width: 576px) {
    top: calc(50% - 84px);
    left: -84px;
  }

  @media (max-width: 575px) {
    bottom: -50px;
    left: 10px;
    width: 128px;
    height: 128px;
  }

  @media (max-width: 399px) {
    width: 88px;
    height: 88px;
    left: -30px;
  }
`

const Image = styled(Plaatjie)`
  height: 139px;
  width: 80%;

  & img {
    object-fit: contain !important;
  }

  @media (max-width: 575px) {
    height: 103px;
  }

  @media (max-width: 399px) {
    height: 76px;
  }
`

const Title = styled.h2`
  height: 114px;

  @media (max-width: 1399px) {
    font-size: 28px;
  }
`

const ArrowWrapper = styled.div``

const PostLink = styled.div<{ hovered: boolean }>`
  color: ${({ theme }) => theme.color.secondary};

  ${(props) =>
    props.hovered &&
    css`
      color: ${({ theme }) => theme.color.primary};
      & svg path {
        fill: ${({ theme }) => theme.color.primary};
      }
    `}
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({
  node,
  blogFields,
  setCurrentIndex,
  index,
  currentIndex,
}) => (
  <motion.div
    onHoverStart={() => setCurrentIndex(index)}
    onHoverEnd={() => setCurrentIndex(-1)}
  >
    <NavLink to={node.uri}>
      <Block grey sharp className="position-relative p-4 p-sm-5">
        <Thumbnail className="position-absolute d-flex justify-content-center align-items-center rounded-circle">
          <Image image={node.recap.thumbnail} alt="" />
        </Thumbnail>
        <div className="ps-sm-5">
          <div className="ps-sm-3">
            <Title>{node.title}</Title>
            <div className="d-flex justify-content-end mt-4">
              <PostLink
                className="font-weight-bold d-flex align-items-center"
                hovered={currentIndex === index}
              >
                {node?.databaseId === 1405 ? (
                  <span>Bekijk deze videocase</span>
                ) : (
                  <span>{blogFields.readmoretext}</span>
                )}
                <ArrowWrapper className="ms-2">
                  <Arrow />
                </ArrowWrapper>
              </PostLink>
            </div>
          </div>
        </div>
      </Block>
    </NavLink>
  </motion.div>
)

export default Cases
